import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Metro01 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_01.webp'
import Metro02 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_02.webp'
import Metro03 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_03.webp'
import Metro04 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_04.webp'
import Metro05 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_05.webp'
import Metro06 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_06.webp'
import Metro07 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_07.webp'
import Metro08 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_08.webp'
import Metro09 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_09.webp'
import Metro10 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_10.webp'
import Metro11 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_11.webp'
import Metro12 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_12.webp'
import Metro13 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_13.webp'
import Metro14 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_DDNS_14.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the Multiview Menu and click the + button in the top right corner to add your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Choose Add a New IP Camera to add your camera via it&apos;s local IP and / or it&apos;s DDNS address."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="It is recommended to use the QRCode Reader to scan the code inside your camera&apos;s webUI. Alternatively, manually select the your camera model...."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="... by choosing your camera manufacturer. Note that you can also add thirdparty camera models..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="...and choose your camera model. For INSTAR cameras you have the choice between the &quot;normal&quot; and RTSP stream. Choosing RTSP sometimes gives you a better performance. But note that the RTSP stream uses it&apos;s own port - default RTSP Port 554 - that has to be forwarded as well to access your camera over the internet"
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select a name for your camera. Each camera you add has to have a unique name."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="We recommend that you use the <strong>Search Function</strong> to automatically add your camera. Alternatively, type in your camera&apos;s DDNS or local IP address and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Type in your cameras LAN (HTTP or HTTPS) port Ihrer Kamera ein und klicken Sie auf Weiter um fortzufahren."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="The Search Function will list all INSTAR IP cameras on the local network. Select the one you want to add..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro10} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="... then click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro11} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Type in your cameras LAN (HTTP or HTTPS) port and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro12} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Type in the admin username for your camera and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro13} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Type in the admin password for your camera and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro14} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Verify that all details are correct and click test and choose the connection over the local network to let the app test the connection to your camera. Note that not all routers allow you to connect via the DDNS address from inside your local network. You might have to disconnect your phone from the local wifi to test the remote access."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function DDNSGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default DDNSGallery